@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

@mixin postStyles {
	display: grid;
	grid-template-columns: 40% 1fr;
	container-type: inline-size;

	@media only screen and (max-width: bp.$breakpoint-medium) {
		grid-template-columns: 1fr;
	}

	.post-thumbnail {

		position: relative;
		overflow: hidden;

		@container (max-width: 600px) {
			grid-column: 1 / -1;

			&>img { 
				aspect-ratio: 16 / 9;
			}
		}

		.post-format {
			position: absolute;
			top: 0;
			left: 0;
			padding: var(--wp--preset--spacing--30) var(--wp--preset--spacing--50);
			color: var(--wp--preset--color--white);
			background-color: var(--wp--preset--color--bnpp-green);
			text-transform: uppercase;
			letter-spacing: 1px;
			display: flex;
			align-items: center;
			gap: 6px;
			font-weight: 400;
			z-index: 999;

			svg {
				width: 25px;
    			height: 25px;
			}
		}

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: 300ms;

			@media only screen and (max-width: bp.$breakpoint-medium) {
				aspect-ratio: 16 / 9;
			}
		}

	}


	.post-content {
		padding: var(--wp--preset--spacing--40);
		background-color: var(--wp--preset--color--white);
		border: solid 1px var(--wp--preset--color--light-grey);
		display: flex;
		flex-direction: column;
		gap: var(--wp--preset--spacing--40);

		@container (max-width: 600px) {
			grid-column: 1 / -1;
			border-left: solid 1px var(--wp--preset--color--light-grey)!important;
		}

		@media only screen and (min-width: bp.$breakpoint-medium) {
			border-left: none;
		}

		* {
			color: var(--wp--preset--color--bnpp-black);
		}

		&>p {
			font-weight: 300;
			font-size: var(--wp--preset--font-size--medium);
			margin: 0;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		h3 {
			font-size: var(--wp--preset--font-size--heading-xs);
			margin: 0;
		}

		.post-meta {
	
			display: flex;
			flex-wrap: wrap;
			gap: var(--wp--preset--spacing--40);
			align-items: center;

			.regular-publication {
				margin: 0;
				text-transform: uppercase;
				color: var(--wp--preset--color--bnpp-green);
				font-size: var(--wp--preset--font-size--body-s);
			}
	
			.post-category {
				background-color: var(--wp--preset--color--bnpp-green-tint-20);
				width: fit-content;
				padding: var(--wp--preset--spacing--30) var(--wp--preset--spacing--40);
				border-radius: 50px;
				font-size: var(--wp--preset--font-size--body-s);
				margin: 0;
				margin-left: auto;
				font-weight: 300;
			}
		}

		.post-info {
	
			display: flex;
			flex-wrap: wrap;
			flex: 1;
			gap: var(--wp--preset--spacing--40);
			align-items: flex-end;
			justify-content: space-between;

			.author-images {
				display: flex;
				align-items: center;
			
				img {
					--imageSize: 70px;
					border-radius: 50%;
					width: var(--imageSize);
					height: var(--imageSize);
					border: solid 4px var(--wp--preset--color--bnpp-green-tint-30);
					&:not(:nth-child(odd)) {
						margin-left: calc(var(--wp--preset--spacing--40) / -1);
					}

					& + .authors {
						margin-left: var(--wp--preset--spacing--40);
					}
				}
			}

			.authors {

				margin: 0;
				font-size: var(--wp--preset--font-size--body-s);

				span {
					display: block;
					font-weight: 300;
				}

			}

			.date {
				color: var(--wp--preset--color--charcoal);
				font-size: var(--wp--preset--font-size--body-s);
				margin: 0;
				font-weight: 400;
			}
		}
	}

	&:hover{

		.post-thumbnail {
			img {
				transform: scale(1.05);
			}
		}

	}

}

@mixin featuredPost {
	grid-area: featured;
	.post-content {
		border-top: none;
	}
}

.posts-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
	gap: var(--wp--preset--spacing--40) var(--wp--preset--spacing--50);
	margin-top: var(--wp--preset--spacing--40);

	.post {
		text-decoration: none;
		@include postStyles;
	}

	@media only screen and (min-width: bp.$breakpoint-medium) {
		&[data-post-count="3"] {
			grid-template-areas: "featured b" "featured c";
			grid-template-columns: none;
			gap: var(--wp--preset--spacing--40) var(--wp--preset--spacing--70);

			.post:first-child {
				
					grid-template-columns: 1fr;
					@include featuredPost;
					.post-content {
						border-left: solid 1px var(--wp--preset--color--light-grey);
					}

			}

			.post:not(:first-child) :is(.post-thumbnail, .post-content) {
				grid-column: auto;
			}

			.post:not(:first-child) .post-content > p {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.post:not(:first-child) .post-content .date {
				display: none;
			}

		}

	}

}

body:is([data-language="de_DE"], [data-language="fr_FR"]) {

    .posts-grid .post .post-content > p {
        display: none;
    }

    .posts-grid .post .post-content .post-meta .regular-publication {
        font-weight: 400;
    }

}